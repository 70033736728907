module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-fontawesome-css@1.2.0_@fortawesome+fontawesome-svg-core@6.7.1_gatsby@5.14.0_bab_c2cseio47lzh6soosmr75xlcri/node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.13.5_react@18.3.1__gatsby@5.14.0_babel-esli_yfbcodioffvgjwbbkhh4zaw36i/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@3.15.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18._khv25khs2i3hxxzlcfbmazkgsq/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"dimgray","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_ht5zrhljucusbxybuphucu3z34/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","theme_color":"#ADB5BD","background_color":"#6C757D","display":"minimal-ui","icon":"src/images/profile-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4228f175205eab642bbce6396b1d3c3f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__tfn55hojogzoc55zgketkao5s4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
